import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import Layout from "../../components/layout"
import Markdown from "react-markdown"
import Seo from "../../components/seo"

export const query = graphql`
  query PageQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      content
      metaTitle
      metaDescription
      mainImage {
        url
      }
      published_at
      updated_at
    }
  }
`

const Page = ({ data }) => {
  return (
    <Layout seo={{}}>
      <Seo title={data.strapiArticle.title} description={data.strapiArticle.metaDescription}/>
      <div className="Post">
        <div className="Post__content maxWidth">
          <img src={data.strapiArticle.mainImage.url} width="300px" />
          <h1>{data.strapiArticle.title}</h1>
          <div  dangerouslySetInnerHTML={{ __html: data.strapiArticle.content }}></div>
          <div>
            <p className="lastUpdated">
              Last Updated: &nbsp;
              <Moment format="MM/DD/YYYY">
                {data.strapiArticle.updated_at}
              </Moment>
            </p>
          </div>
        </div>

      </div>

    </Layout>
  )
}

export default Page
